import React from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "../components/SiteMetadata";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
  appPalette,
} from "../style/design-system";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";
import Faq from "../components/Faq";

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes.lg};
  text-align: ${(p) => (p.center ? `center` : `left`)};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const SectionHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
    margin: 0 0 ${sizes.xs} 0;
  }
`;

const SectionTagline = styled.h4`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes.xl} auto;
  }
`;
const FaqContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const FaqPageTemplate = ({
  title,
  subtitle,
  faqs,
  metaTitle,
  location,
}) => {
  const { title: siteTitle, siteUrl } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <title>{`${metaTitle} | ${siteTitle}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
      </Helmet>
      <OuterContainer>
        <SectionInnerContainer center>
          <SectionHeader>{title}</SectionHeader>
          <SectionTagline>{subtitle}</SectionTagline>
        </SectionInnerContainer>
      </OuterContainer>
      <OuterContainer>
        <SectionInnerContainer>
          <FaqContainer>
            {faqs.faq.map((faq) => (
              <Faq
                question={faq.question}
                answer={faq.answer}
                key={faq.question}
              ></Faq>
            ))}
          </FaqContainer>
        </SectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const FaqPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout language={frontmatter.language}>
      <FaqPageTemplate
        location={location}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        faqs={frontmatter.faqs}
        metaTitle={frontmatter.metaTitle}
      />
    </Layout>
  );
};

export default FaqPage;

export const pageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        metaTitle
        language
        title
        subtitle
        faqs {
          faq {
            question
            answer
          }
        }
      }
    }
  }
`;
