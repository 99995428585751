import React, { useState } from "react";
import styled from "styled-components";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
  appPalette,
} from "../style/design-system";
import ToggleButton from "./ToggleButton";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled.div`
  margin-bottom: ${sizes.md};
`;
const Question = styled(ToggleButton)`
  width: fit-content;
  padding: ${sizes.xs} ${sizes.md};
  z-index: 2;
  position: relative;
`;
const Answer = styled(motion.p)`
  background: ${colorAliases.beige};
  padding: ${sizes.md} ${sizes.lg};
  border-radius: ${borderRadius.md};
  max-width: 80ch;
  margin-top: ${sizes.xxs};
`;

export const Faq = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Question toggle={setOpen}>{question}</Question>
      <AnimatePresence>
        {open && (
          <Answer
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
          >
            {answer}
          </Answer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Faq;
