import React from "react";
import { useToggleButton } from "@react-aria/button";
import { useToggleState } from "@react-stately/toggle";
import styled from "styled-components";
import { useRef, useEffect } from "react";
import PlayIcon from "./PlayIcon";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
  appPalette,
} from "../style/design-system";

const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: fit-content;
  border-radius: ${borderRadius.md};
  background: white;
  color: ${(p) => (p.isSelected ? `inherit` : `inherit`)};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colorAliases.turqoiseLight};
  }
  &:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${colorAliases.turqoiseLight};
  }
`;

const StyledPlayIcon = styled(PlayIcon)``;
const Question = styled.h3`
  margin: 0;
  width: fit-content;
`;

function ToggleButton(props) {
  let ref = useRef();
  let state = useToggleState(props);
  let useToggleResult = useToggleButton(
    { ...props, elementType: `div` },
    state,
    ref
  );
  let myToggle = props.toggle;

  let { buttonProps } = useToggleResult;

  useEffect(() => {
    myToggle(state.isSelected);
  }, [state.isSelected, myToggle]);

  return (
    <Container
      ref={ref}
      className={props.className}
      {...buttonProps}
      isSelected={state.isSelected}
    >
      <StyledPlayIcon
        color={colorAliases.textColor}
        isSelected={state.isSelected}
        translateX={-10}
      ></StyledPlayIcon>
      <Question>{props.children}</Question>
    </Container>
  );
}

export default ToggleButton;
